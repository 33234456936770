import React, { useEffect, useRef, useMemo } from 'react';
import anime from 'animejs';

const StaggerVisualizer = ({ grid = [10, 10] }) => {
  const staggerVisualizerRef = useRef(null);
  const numberOfElements = useMemo(() => grid[0] * grid[1], [grid]);

  useEffect(() => {
    if (staggerVisualizerRef.current) {
      staggerVisualizerRef.current.innerHTML = ''; // Clear previous elements
      const fragment = document.createDocumentFragment();

      for (let i = 0; i < numberOfElements; i++) {
        const div = document.createElement('div');
        div.style.position = 'absolute'; // Position all divs on top of each other
        div.style.left = '50%';
        div.style.top = '50%';
        div.style.transform = 'translate(-50%, -50%)';
        fragment.appendChild(div);
      }

      staggerVisualizerRef.current.appendChild(fragment);
    }

    const staggersAnimation = anime.timeline({
      targets: '.stagger-visualizer div',
      easing: 'easeInOutSine',
      loop: true,
      autoplay: false,
    })
      .add({
        translateX: 0,
        translateY: 0,
        scale: 1,
        duration: 1000,
      })
      .add({
        translateX: () => anime.random(-180, 180),
        translateY: () => anime.random(-180, 180),
        scale: 1,
        duration: 1000,
        delay: anime.stagger(10, { from: 'center' }),
      })
      .add({
        translateX: anime.stagger('1rem', { grid: grid, from: 'center', axis: 'x' }),
        translateY: anime.stagger('1rem', { grid: grid, from: 'center', axis: 'y' }),
        rotate: 0,
        scaleX: 2.5,
        scaleY: 0.5,
        duration: 1000,
        delay: anime.stagger(10, { from: 'center' }),
      })
      .add({
        translateX: 0,
        translateY: 0,
        scale: 1,
        scaleX: 1,
        scaleY: 1,
        rotate: 0,
        duration: 1000,
      });

    staggersAnimation.play();
  }, [numberOfElements, grid]);

  return (
    <div className="stagger-visualizer" ref={staggerVisualizerRef}></div>
  );
};

export default StaggerVisualizer;