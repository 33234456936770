import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import HeroHeader from '../components/HeroHeader';
import ProjectsGrid from '../components/ProjectsGrid';
import { projects } from '../data/projects';
import StaggerVisualizer from '../components/StaggerVisualizer';


const Home = () => {

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const target = document.querySelector(location.hash);
      if (target) {
        target.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.hash])

  return (
    <div>
      <Navbar />
      <HeroHeader
        h1Text="Hello There!"
        pText="I'm Nicola Bolton - a Senior UI Developer with 15+ years of experience bridging design and engineering to foster seamless collaboration and find clever ways to solve problems."
        links={[
          {
            text: "View Projects",
            href: "#projects",
            primary: true,
          },
          {
            text: "Contact Me",
            href: "#contact",
            primary: false,
          },
        ]}
        showProfileImage
      />
      <section id="about" className="p-6">
        <div className="mt-24 mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 items-center lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:gap-y-10">
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="lg:max-w-lg">
                <h4>About Me</h4>
                <h2>
                  Creativity Meets Functionality.
                </h2>
                <p>
                  I am a Senior UI developer born in England and based in Collingwood, ON. Currently working at Designlab to help improve and expand their rapidly-growing online education platform.
                </p>
                <p>
                  What makes me unique is my dual proficiency in both design and code. I possess a keen design eye honed through years in design roles, coupled with the technical prowess to bring those designs to life. Whether it’s refining user experiences or developing interactive features, I thrive on creating cohesive and impactful digital products.
                </p>
              </div>
            </div>
          </div>
          <div className="justify-items-center lg:col-start-2 lg:row-span-2 lg:row-start-1">
            {/* animation */}
            <div className="relative w-full max-w-md h-96 overflow-hidden">
              <StaggerVisualizer grid={[10, 10]} />
            </div>
          </div>
        </div>
      </section>
      <section id="projects" className="p-6 md:px-24">
        <div className="mt-24">
          <div className="mx-auto lg:max-w-7xl">
            <h4 class="text-center">Selection of Work</h4>
            <h2 class="text-center">
              Bridging Design and Code.
            </h2>
            <ProjectsGrid projects={projects} />
          </div>
        </div>
      </section>
      <section id="contact" className="p-6">
        <div className="mt-24 pb-32">
          <div className="relative isolate pt-14">
            <div
              aria-hidden="true"
              className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            >
              <div
                style={{
                  clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
                className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#A0B2C8] to-[#1E5FF1] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                /></div>
              <div className="mx-auto max-w-2xl text-center">
                <h4>Contact</h4>
                <h2>Let's connect.</h2>
                <p>I’d love to hear from you! Whether you’re looking to collaborate on a project, discuss a potential opportunity, or simply want to connect, <br/>feel free to <a className="text-[#1E5FF1] hover:text-[#104CD4]" href="mailto:nicolaebolton@gmail.com">reach out</a>.</p>
                <div className="flex gap-4 items-center justify-center my-8">
                  <a target="_blank" className="w-8 h-8" href="https://github.com/nbolton00" rel="noreferrer">
                    <svg
                      className="w-full h-full fill-[#1E5FF1] hover:fill-[#104CD4]"
                      viewBox="0 0 98 96"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M48.854 0C21.839 0 0 22 0 49.217c0 21.756 13.993 40.172 33.405 46.69 2.427.49 3.316-1.059 3.316-2.362 0-1.141-.08-5.052-.08-9.127-13.59 2.934-16.42-5.867-16.42-5.867-2.184-5.704-5.42-7.17-5.42-7.17-4.448-3.015.324-3.015.324-3.015 4.934.326 7.523 5.052 7.523 5.052 4.367 7.496 11.404 5.378 14.235 4.074.404-3.178 1.699-5.378 3.074-6.6-10.839-1.141-22.243-5.378-22.243-24.283 0-5.378 1.94-9.778 5.014-13.2-.485-1.222-2.184-6.275.486-13.038 0 0 4.125-1.304 13.426 5.052a46.97 46.97 0 0 1 12.214-1.63c4.125 0 8.33.571 12.213 1.63 9.302-6.356 13.427-5.052 13.427-5.052 2.67 6.763.97 11.816.485 13.038 3.155 3.422 5.015 7.822 5.015 13.2 0 18.905-11.404 23.06-22.324 24.283 1.78 1.548 3.316 4.481 3.316 9.126 0 6.6-.08 11.897-.08 13.526 0 1.304.89 2.853 3.316 2.364 19.412-6.52 33.405-24.935 33.405-46.691C97.707 22 75.788 0 48.854 0z"
                      />
                    </svg>
                  </a>
                  <a target="_blank" className="w-8 h-8" href="https://www.linkedin.com/in/nicolaerinbolton" rel="noreferrer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 50 50"
                      className="w-full h-full fill-[#1E5FF1] hover:fill-[#104CD4]"
                    >
                      <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"/>
                    </svg>
                  </a>
                </div>
              </div>
              <div
                aria-hidden="true"
                className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
              >
                <div
                  style={{
                    clipPath:
                      'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                  }}
                  className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#A0B2C8] to-[#1E5FF1] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                />
              </div>
            </div>
          </div>
      </section>
    </div>
  );
};

export default Home;


