import ProfileImage from '../images/nicola_bolton.jpg';

const HeroHeader = ({ h1Text, pText, links, content, showProfileImage = false }) => {
  return (
    <div className="relative bg-white">
      <div className="relative isolate px-6 pt-14">
        <div
          aria-hidden="true"
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        >
          <div
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#A0B2C8] to-[#1E5FF1] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          />
        </div>
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-32">
          <div className="text-center">
            {showProfileImage && (
              <img
                src={ProfileImage}
                alt="Nicola Bolton"
                className="rounded-full bg-gray-100 object-cover dark:bg-gray-800 w-20 h-20 mx-auto mb-4"
              />
            )}

            <h1>
              {h1Text}
            </h1>
            <p>
              {pText}
            </p>
            {links ? (
              <div className="mt-10 flex items-center justify-center gap-x-6">
                {links.map((link, index) => (
                  <a
                    key={index}
                    href={link.href}
                    className={
                      link.primary
                        ? "rounded-md bg-[#1E5FF1] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#104CD4] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1E5FF1]"
                        : "text-sm font-semibold text-gray-900 hover:text-[#1E5FF1]"
                    }
                  >
                    {link.text}
                  </a>
                ))}
              </div>
            ) : (
              <p className="mt-6 text-base leading-8 text-[#1E5FF1]">{content}</p>
            )}
          </div>
        </div>
        <div
          aria-hidden="true"
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        >
          <div
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#A0B2C8] to-[#1E5FF1] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          />
        </div>
      </div>
    </div>
  );
};

export default HeroHeader;