import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ProjectCard = ({ project, gridSpan }) => {
  return (
    <div className={`relative ${gridSpan}`}>
      {/* Background and Shadow */}
      <div className="absolute inset-px rounded-lg bg-white"></div>

      {/* Card Content */}
      <div className="relative flex h-full flex-col overflow-hidden">
        {/* Header */}
        <div className="p-8">
          <h3 className="mt-2 text-lg font-medium tracking-tight text-gray-900">
            {project.title}
          </h3>
          <p className="text-base text-gray-600">
            {project.description}
          </p>
          <p className="text-base">
            <Link to={`/projects/${project.slug}`} className="text-[#1E5FF1]">View Case Study</Link>
          </p>
        </div>
        {/* Content */}
        {project.layoutType === 'image' && (
          <div className="relative w-full grow px-8">
            <div className="relative h-full">
              <img
                className="w-full h-auto object-cover object-top rounded-t-2xl"
                src={project.image}
                alt={project.title}
              />
            </div>
          </div>
        )}
        {project.layoutType === 'animated' && (
          <div className="relative w-full grow px-8">
            <div className="relative h-full">
              <img
                className="w-1/2 h-auto"
                src={project.image}
                alt={project.title}
              />
            </div>
          </div>
        )}
        {project.layoutType === 'code' && (
          <div className="relative min-h-[30rem] w-full grow">
            <div className="absolute inset-x-10 bottom-0 top-10 overflow-hidden rounded-t-[12cqw] bg-gray-900 shadow-2xl">
              <div className="flex bg-gray-800/40 ring-1 ring-white/5">
                <div className="-mb-px flex text-sm font-medium text-gray-400">
                  <div className="border-b border-r border-b-white/20 border-r-white/10 bg-white/5 px-4 py-2 text-white">
                    NotificationSetting.jsx
                  </div>
                  <div className="border-r border-gray-600/10 px-4 py-2">App.jsx</div>
                </div>
              </div>
              <pre className="px-6 pb-14 pt-6 text-sm text-gray-300">
                {project.codeSnippet}
              </pre>
            </div>
          </div>
        )}

      </div>

      {/* Shadow Layer */}
      <div className="pointer-events-none absolute inset-px rounded-2xl shadow ring-1 ring-black/5"></div>
    </div>
  );
};

ProjectCard.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string,
    codeSnippet: PropTypes.string,
    layoutType: PropTypes.oneOf(['image', 'code']).isRequired,
  }).isRequired,
  gridSpan: PropTypes.string,
};

ProjectCard.defaultProps = {
  gridSpan: '',
};

export default ProjectCard;
