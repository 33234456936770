import React from 'react';
import ProjectCard from './ProjectCard';
import PropTypes from 'prop-types';

const ProjectsGrid = ({ projects }) => {
  return (
    <div className="mt-10 grid grid-cols-1 md:grid-cols-6 gap-4 sm:mt-16">
      {projects.map((project) => (
        <ProjectCard
          key={project.id}
          project={project}
          gridSpan={project.gridSpan || 'col-span-6 md:col-span-2'}
        />
      ))}
    </div>
  );
};

ProjectsGrid.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.string,
      codeSnippet: PropTypes.string,
      layoutType: PropTypes.oneOf(['image', 'code']).isRequired,
      gridSpan: PropTypes.string, // Optional for custom grid placement
    })
  ).isRequired,
};

export default ProjectsGrid;