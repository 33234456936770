import DesignlabImage from '../images/designlab_1.jpg';
import SPTImage1 from '../images/spt_student.jpg';
import SPTImage2 from '../images/spt_student2.png';
import SPTImage3 from '../images/spt_mentorcard.png';
import SPTImage4 from '../images/spt_progresscard.png';
import StorybookImage1 from '../images/storybook_2.jpg';
import StorybookImage2 from '../images/storybook_5.jpg';
import StorybookGif from '../images/storybook_book.gif';
import YorkChapelImage1 from '../images/yorkchapel_1.jpg';
import YorkChapelImage2 from '../images/yorkchapel_4.png';
import YorkChapelImage3 from '../images/yorkchapel_3.png';
import ApplioImage from '../images/applio_1.jpg';
import ApplioImage3 from '../images/applio_3.jpg';
import ApplioImage4 from '../images/applio_4.png';
import ApplioImage5 from '../images/applio_5.jpg';
import ApplioImage6 from '../images/applio_6.jpg';
import ApplioImage7 from '../images/applio_7.jpg';
import PortlandCoffeeImage from '../images/pcc_1.jpg';
import PortlandCoffeeImage2 from '../images/pcc_2.png';
import PortlandCoffeeImage3 from '../images/pcc_3.png';
import PortlandCoffeeImage4 from '../images/pcc_4.png';

export const projects = [
  {
    id: 1,
    slug: 'student-progress',
    title: 'Rethinking Student Progress Tracking',
    description: "Redesigning Designlab's progress tracker to improve clarity, boost motivation, and drive higher course completion rates.",
    image: DesignlabImage,
    layoutType: 'image',
    meta: 'Front End Development | 2023',
    gridSpan: 'col-span-6 md:col-span-3',
    details: {
      images: [
        {
          src: SPTImage1,
          alt: "Student Progress Tracking 1",
          className: "relative w-full md:w-[95%] shadow-xl mb-8"
        },
        {
          src: SPTImage2,
          alt: "Student Progress Tracking 1",
          className: "relative w-full"
        },
        {
          src: SPTImage3,
          alt: "Student Progress Tracking 2",
          className: "relative w-full md:absolute md:-bottom-4 md:right-0 md:z-20 md:w-1/2"
        },
        {
          src: SPTImage4,
          alt: "Student Progress Tracking 3",
          className: "relative w-full md:absolute md:-bottom-24 md:left-10 md:z-30 md:w-1/2"
        }
      ],
      stats: [
        {
          label: "support tickets related to progress tracking",
          value: "94% decrease"
        },
        {
          label: "on-time course completions",
          value: "26% increase"
        },
        {
          label: "overall completion rates",
          value: "15% boost"
        }
      ],
      content: `
          <h3>Project Overview</h3>
          <p>Students in Designlab’s UX Academy faced challenges tracking their course progress, leading to confusion and missed deadlines. The revamped progress tracker aimed to improve clarity, boost motivation, and enhance completion rates for both students and mentors.</p>
          <h3>Key Features</h3>
          <ul>
            <li><strong>Enhanced Progress Visualization:</strong> Clear visual indicators for milestones and deadlines.</li>
            <li><strong>Actionable Insights for Mentors:</strong> Streamlined tools for tracking and supporting students.</li>
            <li><strong>Real-time Updates:</strong> Seamless experience across devices using Tailwind CSS and React.</li>
          </ul>
          <h3>My Role</h3>
          <p>As the Lead Front-End Developer, I ensured that all components were reusable, well-documented, and met accessibility best practices. The front end primarily relied on data provided by Django context, which I integrated to enable accurate and dynamic progress tracking. A key part of my role was implementing complex features to calculate and display project deadlines, overdue status, and hours behind schedule. I worked closely with the design team to translate user feedback into impactful UX improvements.</p>
          <p>We launched the progress tracking feature to the August cohort of UX Academy (UXA) after a successful beta test with the July cohort. The feature received a <strong>4.6/5 usefulness rating</strong> from surveyed students. Many praised its motivational impact, particularly the progress visualisation and module due date indicators, which helped them better plan their schedules.</p>
          <h3>Testimonials</h3>
          <blockquote class="text-xl/8 font-semibold text-gray-900 sm:text-2xl/9">
            <p>“Knowing when each module is due to be completed is very motivating. It's also quite encouraging to know when you're ahead of schedule.”</p>
          </blockquote>
          <div class="mt-4 flex items-center space-x-3 text-base">
            <div class="font-semibold text-gray-900">Chelsea</div>
            <svg viewBox="0 0 2 2" width="3" height="3" aria-hidden="true" class="fill-gray-900">
              <circle cx="1" cy="1" r="1"></circle>
            </svg>
            <div class="text-gray-600">UXA Student</div>
          </div>

          <blockquote class="text-xl/8 font-semibold text-gray-900 sm:text-2xl/9">
            <p>“The new student progress tracker…WOW!!!!!!!!!!!!!!! Seriously though, as a mentor that has been with Designlab for a while, I can't tell you all how much GOLDEN this is!”</p>
          </blockquote>
          <div class="mt-4 flex items-center space-x-3 text-base">
            <div class="font-semibold text-gray-900">Darold</div>
            <svg viewBox="0 0 2 2" width="3" height="3" aria-hidden="true" class="fill-gray-900">
              <circle cx="1" cy="1" r="1"></circle>
            </svg>
            <div class="text-gray-600">Designlab Mentor</div>
          </div>
      `,
    },
  },
  {
    id: 2,
    slug: 'component-library',
    title: 'Implementing a Storybook Component Library',
    description: "Building a scalable, maintainable library that fosters collaboration and consistency at Designlab.",
    image: StorybookGif,
    layoutType: 'image',
    meta: 'Front End Development | 2023',
    gridSpan: 'col-span-6 md:col-span-3',
    details: {
      images: [
        {
          src: StorybookImage1,
          alt: "Storybook Component Library",
          className: "relative w-full shadow-2xl mb-12 rounded-xl"
        },
        {
          src: StorybookImage2,
          alt: "Storybook Component Library",
          className: "relative w-full"
        },
      ],
      content: `
          <h3>Objective</h3>
          <p>Develop a centralized Storybook component library to enhance collaboration, ensure UI consistency, and document accessibility standards. This ongoing project addresses challenges with fragmented documentation and inconsistent component usage across teams.</p>
          <h3>Key Features</h3>
          <ul>
            <li><strong>Centralized Repository:</strong> A shared resource of prebuilt components for designers, developers, and stakeholders.</li>
            <li><strong>Comprehensive Documentation:</strong> Detailed guidelines on use cases, WCAG compliance, and implementation best practices.</li>
            <li><strong>Project Rendering:</strong> Render entire projects in Storybook to evaluate cascading UI impacts.</li>
            <li><strong>Optimized Deployments:</strong> Chromatic trigger file mechanism for efficient resource management.</li>
          </ul>
          <h3>My Role</h3>
          <p>I led the implementation of the Storybook component library, creating a structured and centralized repository for prebuilt components.</p>
          <p>I collaborated closely with designers to document use cases, accessibility considerations, and best practices, ensuring the library met both design and development needs. </p>
          <p>To streamline workflows, I optimized Chromatic deployments, reducing resource usage, and integrated project-wide rendering in Storybook to catch cascading UI issues early. My focus was on building a scalable, maintainable library that fosters collaboration and consistency across teams.</p>
      `,
    },
  },
  {
    id: 3,
    slug: 'york-chapel',
    title: 'York & Chapel',
    description: 'UX/UI design and development for national and global clients.',
    image: YorkChapelImage1,
    layoutType: 'image',
    gridSpan: 'col-span-6 md:col-span-2',
    meta: 'UI Design, Front-end Development | 2018-2020',
    details: {
      images: [
        {
          src: YorkChapelImage2,
          alt: "York and Chapel",
          className: "relative w-full mb-12"
        },
        {
          src: YorkChapelImage3,
          alt: "York and Chapel",
          className: "relative w-full"
        },
      ],
      stats: [
        {
          label: "Delivered CORA (COVID-19 risk assessment tool) MVP",
          value: "6 weeks"
        },
        {
          label: "Registrations for Intuit's first Virtual Event",
          value: "18,500 in 48h"
        },
      ],
      content: `
        <p>In my role at York & Chapel, I supported various UX/UI design tasks, including creative direction and concept development. This involved:</p>

        <ul>
          <li>Developing user journeys, personas, and site maps to better understand user needs.</li>
          <li>Planning and delivering wireframes and clickable prototypes used in user testing sessions.</li>
          <li>Designing and building custom WordPress and HubSpot themes for a range of well-known national and global start-ups, such as Real-Time Innovations (RTI), Jaxjox, InPath, ArcoInfo, and Eastern Accounts.</li>
        </ul>
        <h3>Key Focus</H3>
        <p>I led a team to maintain Intuit's high-profile global event websites and apps, QuickBooks Connect. My responsibilities included overseeing production specs, facilitating design reviews with stakeholders, and conducting design and development QA for both my tasks and those of my teammates. I also made ongoing design and code iterations based on user research to improve the product.</p>

        <p>I worked closely with Intuit's Project Managers and Head of Design to ensure that the QuickBooks brand system was consistently applied across all products, and I created new design patterns as needed to adapt to evolving requirements.</p>
      `,
    },
  },
  {
    id: 4,
    slug: 'applio',
    title: 'Applio',
    description:
      'Tenancy progression automation tool for real estate agents, from ideation to execution.',
    image: ApplioImage,
    layoutType: 'image',
    gridSpan: 'col-span-6 md:col-span-2',
    meta: 'Branding, UX and UI Design | 2020',
    details: {
      images: [
        {
          src: ApplioImage4,
          alt: "Applio",
          className: "relative w-full mb-12"
        },
        {
          src: ApplioImage5,
          alt: "Applio",
          className: "relative w-full mb-12 shadow-2xl rounded-xl"
        },
        {
          src: ApplioImage3,
          alt: "Applio",
          className: "relative w-full mb-12 shadow-2xl rounded-xl"
        },
        {
          src: ApplioImage6,
          alt: "Applio",
          className: "relative w-full mb-12 shadow-2xl rounded-xl"
        },
        {
          src: ApplioImage7,
          alt: "Applio",
          className: "relative w-full mb-12 shadow-2xl rounded-xl"
        },
      ],
      stats: [
        {
          label: "in admin time during pre-tenancy process",
          value: "50% reduction"
        },
      ],
      content: `
        <p>Applio gives Letting Agents the ability to manage the entire pre-tenancy process in one place, automating many of the manual tasks involved in tenancy progression.</p>

      <p>Traditional methods of renting a property are often complicated and time-consuming for all parties—agents, tenants, and landlords. With forms to complete, contracts to sign, and files to organize, the process can feel overwhelming. Applio streamlines this process, addressing the evolving challenges of the lettings industry, including online competition, new legislation, and rising customer expectations.</p>

      <h3>My role</h3>
      <p>I partnered with a project manager to identify user needs and translate insights into features that align with user behaviors and motivations. I developed frameworks and prototypes to visualize ideas, establish design principles, and guide decision-making among stakeholders. Collaborating closely with engineers, I helped define the product, prioritize features, and negotiate scope for both the initial launch and future iterations.</p>

      <p>The product name, "Applio," derived from the Latin word for "applies," reflects its purpose of facilitating tenant applications. I designed the logo and branding to align with this vision. To ensure a seamless user experience, I crafted user journeys, wireframes, prototypes, and detailed design specifications, while establishing a UI kit of reusable components to maintain consistency across the product.</p>

      <h3>The Process</h3>
      <p>In the exploratory phase, we conducted interviews with Letting Agents to understand their workflows and pain points. Using these insights, I mapped out use cases and iterated rapidly through sketching, wireframing, and prototyping. Stakeholder feedback and user testing helped refine the design.</p>

      <p>The interface was designed to be clean and distraction-free, using a calming palette of blues and whites to focus users on completing their tasks.</p>

      <h3>Outcomes & Lessons Learned</h3>
      <p>Applio has significantly streamlined the pre-tenancy process, reducing admin time by up to 50% for stakeholders and enabling them to take on more properties. The MVP has been well-received, and we are exploring expansion to other agencies while refining the UI and developing the Applio design system.</p>

      <p>This project demonstrated the value of user feedback and iterative design in delivering an intuitive and effective product.</p>
      `,
    },
  },
  {
    id: 5,
    slug: 'portland-coffee',
    title: 'Portland Coffee Co.',
    description:
      'Exclusive new coffee house freshly brewed by Nottingham University Students’ Union',
      image: PortlandCoffeeImage,
      layoutType: 'image',
    gridSpan: 'col-span-6 md:col-span-2',
    meta: 'Branding, Print, Digital Assets, UI Design, Front-end Development | 2016-2017',
    details: {
      images: [
        {
          src: PortlandCoffeeImage3,
          alt: "Applio",
          className: "relative w-full mb-12"
        },
        {
          src: PortlandCoffeeImage4,
          alt: "Applio",
          className: "relative w-full mb-12"
        },
        {
          src: PortlandCoffeeImage2,
          alt: "Applio",
          className: "relative w-full mb-12"
        },
      ],
      content: `
        <p>In 2017, I was contracted by the University of Nottingham Students' Union to lead the artistic direction for their new in-house coffee shop initiative, Portland Coffee Co. The goal was to create a brand identity that resonated with the student audience while aligning with the SU's new brand guidelines and messages.</p>

      <h3>Branding & Identity</h3>
      <p>I developed a cohesive brand that captured the essence of the student community. The visual identity was designed to be vibrant, welcoming, and reflective of the SU's mission, ensuring that it stood out in a competitive campus environment.</p>

      <h3>Design Execution</h3>
      <p>Once the brand identity was established, I designed and produced a variety of print and digital collateral, including:</p>
      <ul>
        <li>A custom WordPress theme to enhance the coffee shop's online presence.</li>
        <li>Business cards and takeaway cup designs to reinforce the brand.</li>
        <li>A bespoke MS Word template for internal use.</li>
        <li>Menu displays and interior signage for an inviting in-store experience.</li>
        <li>Promotional items, including posters for live events and seasonal campaigns.</li>
      </ul>

      <h3>Outcomes & Lessons Learned</h3>
      <p>This project provided a unique opportunity to blend creative design with practical applications, ensuring that the coffee shop's branding was consistently represented across all touchpoints. The result was a well-received identity that not only enhanced the coffee shop's visibility but also contributed to its success as a key space for student engagement on campus.</p>
      `,
    },
  },
  // Add more projects with `gridSpan` as needed
];
